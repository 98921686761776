import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "../css/navbar.css"


function NavbarCustom() {
  const { authToken, logout } = useAuth();
  return (
    <Navbar expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
        <i style={{fontSize: 30, marginRight: 10}}>√</i> HyperBot
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="nav-link">
              Dashboard
            </Nav.Link>
          </Nav>
          <Nav>
            {authToken ? (
              <Button variant="primary" onClick={logout}>
                Logout
              </Button>
            ) : (
              <span className="navbar-text">Logged out</span>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;