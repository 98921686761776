import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useAuth } from '../context/AuthContext'; 

const LoginCard = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [loginMessage, setLoginMessage] = useState('');

  const { login } = useAuth();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoginMessage("Logging in...");
    try {
      await login(loginData.email, loginData.password);
      setLoginMessage('Login successful');
    } catch (error) {
      setLoginMessage('Login error: ' + (error.response && error.response.data ? JSON.stringify(error.response.data) : error.message));
    }
  };

  return (
    <Card bg="dark" text="white">
      <Card.Body>
        <Card.Title>Login</Card.Title>
        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formBasicEmail1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={loginData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword1">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={loginData.password}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
        {loginMessage && <div className="mt-3">{loginMessage}</div>}
      </Card.Body>
    </Card>
  );
};

export default LoginCard;
