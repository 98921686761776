import React, { useState, useEffect } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GenericModal from "./GenericModal";
import { useNavigate } from "react-router-dom";

const OrderBooks = () => {
  const { authAxios } = useAuth();
  const navigate = useNavigate();
  const [orderBooks, setOrderBooks] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [newOrderBook, setNewOrderBook] = useState({
    base: "",
    quote: "",
    wallet: "",
    config: null,
  });
  const [editingOrderBook, setEditingOrderBook] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState("");
  const [modalCallback, setModalCallback] = useState(() => () => {});

  const fetchOrderBooks = async () => {
    try {
      const response = await authAxios.get(
        `${process.env.REACT_APP_API_URL_HYP}/product/orderbook/`
      );
      setOrderBooks(response.data);
    } catch (error) {
      console.error("Error fetching order books:", error);
    }
  };

  const fetchWallets = async () => {
    try {
      const response = await authAxios.get(
        `${process.env.REACT_APP_API_URL_HYP}/product/wallet/`
      );
      setWallets(response.data);
    } catch (error) {
      console.error("Error fetching wallets:", error);
    }
  };

  const fetchConfigs = async () => {
    try {
      const response = await authAxios.get(
        `${process.env.REACT_APP_API_URL_HYP}/product/config/`
      );
      setConfigs(response.data);
    } catch (error) {
      console.error("Error fetching configs:", error);
    }
  };

  const addOrderBook = async () => {
    try {
      await authAxios.post(
        `${process.env.REACT_APP_API_URL_HYP}/product/orderbook/`,
        newOrderBook
      );
      setNewOrderBook({ base: "", quote: "", wallet: "", config: null });
      fetchOrderBooks();
    } catch (error) {
      console.error("Error adding order book:", error);
    }
  };

  const deleteOrderBook = async (id) => {
    try {
      await authAxios.delete(
        `${process.env.REACT_APP_API_URL_HYP}/product/orderbook/${id}/`
      );
      fetchOrderBooks();
    } catch (error) {
      console.error("Error deleting order book:", error);
    }
  };

  useEffect(() => {
    fetchOrderBooks();
    fetchWallets();
    fetchConfigs();
  }, []);

  const handleDelete = (id) => {
    setModalQuestion("Are you sure you want to delete this order book?");
    setModalCallback(() => () => {
      deleteOrderBook(id);
      setShowModal(false);
    });
    setShowModal(true);
  };

  const handleRowClick = (id) => {
    navigate(`/orderbook/${id}`);
  };

  return (
    <Card bg="dark">
      <Card.Header>
        <h3>Order Books</h3>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Add Order Book</Form.Label>
            <Row>
              <Col xs={12} md={3}>
                <Form.Control
                  type="text"
                  placeholder="Enter base"
                  value={newOrderBook.base}
                  onChange={(e) =>
                    setNewOrderBook({ ...newOrderBook, base: e.target.value })
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <Form.Control
                  type="text"
                  placeholder="Enter quote"
                  value={newOrderBook.quote}
                  onChange={(e) =>
                    setNewOrderBook({ ...newOrderBook, quote: e.target.value })
                  }
                />
              </Col>
              <Col xs={12} md={3}>
                <Form.Control
                  as="select"
                  placeholder="Select wallet"
                  value={newOrderBook.wallet}
                  onChange={(e) =>
                    setNewOrderBook({
                      ...newOrderBook,
                      wallet: parseInt(e.target.value),
                    })
                  }
                >
                  <option value="">Select Wallet</option>
                  {wallets.map((wallet) => (
                    <option key={wallet.id} value={wallet.id}>
                      {wallet.address}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs={12} md={3}>
                <Form.Control
                  as="select"
                  placeholder="Select config"
                  value={newOrderBook.config}
                  onChange={(e) =>
                    setNewOrderBook({
                      ...newOrderBook,
                      config: e.target.value === "" ? null : parseInt(e.target.value),
                    })
                  }
                >
                  <option value="">Select Config</option>
                  {configs.map((config) => (
                    <option key={config.id} value={config.id}>
                      {config.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Button variant="primary" onClick={addOrderBook} className="mt-3">
            Add Order Book
          </Button>
        </Form>

        <TableContainer className="mt-2">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Base</TableCell>
                <TableCell>Quote</TableCell>
                <TableCell>Wallet</TableCell>
                <TableCell>Config</TableCell>
                <TableCell>Running</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBooks.map((orderBook) => (
                <TableRow key={orderBook.id} onClick={() => handleRowClick(orderBook.id)} style={{ cursor: 'pointer' }}>
                  <TableCell>{orderBook.id}</TableCell>
                  <TableCell>{orderBook.base}</TableCell>
                  <TableCell>{orderBook.quote}</TableCell>
                  <TableCell>
                    {wallets.find(wallet => wallet.id === orderBook.wallet)?.address || "N/A"}
                  </TableCell>
                  <TableCell>
                    {configs.find(config => config.id === orderBook.config)?.name || "N/A"}
                  </TableCell>
                  <TableCell>{orderBook.is_running ? "Yes" : "No"}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(orderBook.id);
                      }}
                      className="ms-2"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>

      <GenericModal
        show={showModal}
        question={modalQuestion}
        onAccept={modalCallback}
        onCancel={() => setShowModal(false)}
      />
    </Card>
  );
};

export default OrderBooks;