import React from 'react';
import { Container } from 'react-bootstrap';
import NavbarCustom from './Navbar';


function Layout({ children }) {

  return (
    <>
    <NavbarCustom/>
      <Container className='mt-5' fluid>
        {children}
      </Container>
    </>
  );
}

export default Layout;
