import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import OrderBook from "./pages/OrderBook";
import { AuthProvider } from "./context/AuthContext";
import { LicenseInfo } from "@mui/x-license-pro";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ReactFlowProvider } from "reactflow";
import PrivateRoute from "./components/PrivateRoute";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const theme = createTheme({
  typography: {
    fontFamily: "Sora, sans-serif",
  },
  palette: {
    text: {
      primary: "#ffffff", // White text for primary text
      secondary: "#cccccc", // Lighter white (greyish) for secondary text, if needed
    },
    action: {
      active: "#ffffff",
      hover: "rgba(255, 255, 255, 0.2)",
      selected: "#ffffff",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute element={<Home />} />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/orderbook/:id",
    element: <OrderBook />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <ReactFlowProvider>
        <RouterProvider router={router} />
      </ReactFlowProvider>
    </ThemeProvider>
  </AuthProvider>
);
