import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import GenericModal from "./GenericModal"; // Adjust the import path as needed

const Wallet = () => {
  const { authAxios } = useAuth();
  const [wallet, setWallet] = useState([]);
  const [privateKey, setPrivateKey] = useState("");
  const [editingWallet, setEditingWallet] = useState(null);
  const [editingPrivateKey, setEditingPrivateKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalQuestion, setModalQuestion] = useState("");
  const [modalCallback, setModalCallback] = useState(() => () => {});

  const fetchWallet = async () => {
    try {
      const response = await authAxios.get(
        `${process.env.REACT_APP_API_URL_HYP}/product/wallet/`
      );
      setWallet(response.data);
    } catch (error) {
      console.error("Error fetching wallet:", error);
    }
  };

  const addWallet = async () => {
    if (privateKey) {
      try {
        await authAxios.post(
          `${process.env.REACT_APP_API_URL_HYP}/product/wallet/from_private_key/`,
          { private_key: privateKey }
        );
        setPrivateKey("");
        fetchWallet();
      } catch (error) {
        console.error("Error adding wallet:", error);
      }
    }
  };

  const generateWallet = async () => {
    try {
      await authAxios.post(
        `${process.env.REACT_APP_API_URL_HYP}/product/wallet/generate/`,
        { private_key: "" }
      );
      fetchWallet();
    } catch (error) {
      console.error("Error adding wallet:", error);
    }
  };

  const deleteWallet = async (id) => {
    try {
      await authAxios.delete(
        `${process.env.REACT_APP_API_URL_HYP}/product/wallet/${id}/`
      );
      fetchWallet();
    } catch (error) {
      console.error("Error deleting wallet:", error);
    }
  };

  const updateWallet = async (id, newPrivateKey) => {
    console.log(newPrivateKey)
    try {
      await authAxios.put(
        `${process.env.REACT_APP_API_URL_HYP}/product/wallet/${id}/`,
        { private_key: newPrivateKey }
      );
      setEditingWallet(null);
      fetchWallet();
    } catch (error) {
      console.error("Error updating wallet:", error);
    }
  };

  useEffect(() => {
    fetchWallet();
  }, []);

  useEffect(() => {
    console.log(wallet);
  }, [wallet]);

  const handleDelete = (id) => {
    setModalQuestion("Are you sure you want to delete this wallet?");
    setModalCallback(() => () => {
      deleteWallet(id);
      setShowModal(false);
    });
    setShowModal(true);
  };

  const handleEdit = (wallet) => {
    setEditingWallet(wallet.id);
    setEditingPrivateKey(wallet.private_key);
  };

  const handleSave = (id) => {
    setModalQuestion("Are you sure you want to update this wallet?");
    setModalCallback(() => () => {
      updateWallet(id, editingPrivateKey);
      setShowModal(false);
    });
    setShowModal(true);
  };

  return (
    <Card bg="dark">
      <Card.Header>
        <h3>Wallet</h3>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Add Wallet</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter private key"
              value={privateKey}
              onChange={(e) => setPrivateKey(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={addWallet} className="mt-3">
            Add Wallet
          </Button>
          <Button
            variant="primary"
            onClick={generateWallet}
            className="mt-3 mx-3"
          >
            Generate Wallet
          </Button>
        </Form>

        <TableContainer className="mt-2">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Public Key</TableCell>
                <TableCell>Private Key</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallet.map((wallet) => (
                <TableRow key={wallet.id}>
                  <TableCell>{wallet.id}</TableCell>
                  <TableCell width={10}>{wallet.address}</TableCell>
                  <TableCell>
                    {editingWallet === wallet.id ? (
                      <TextField
                        fullWidth
                        value={editingPrivateKey}
                        onChange={(e) => setEditingPrivateKey(e.target.value)}
                      />
                    ) : (
                      wallet.private_key
                    )}
                  </TableCell>
                  <TableCell align="right">
                    {/* {editingWallet === wallet.id ? (
                      <Button
                        variant="success"
                        onClick={() => handleSave(wallet.id)}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        onClick={() => handleEdit(wallet)}
                      >
                        Edit
                      </Button>
                    )} */}
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(wallet.id)}
                      className="ms-2"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>

      <GenericModal
        show={showModal}
        question={modalQuestion}
        onAccept={modalCallback}
        onCancel={() => setShowModal(false)}
      />
    </Card>
  );
};

export default Wallet;
