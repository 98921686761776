import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import LoginCard from "../components/LoginCard"

function Login() {
  return (
      <Row style={{height: "100vh"}} className="d-flex align-content-center justify-content-center">
        <Col xs={12} md={4} className="mx-2">
          <LoginCard />
        </Col>
      </Row>
  );
}

export default Login;
