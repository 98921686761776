import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import "../css/card.css"
import Wallets from "../components/Wallets";
import OrderBooks from "../components/OrderBooks";

function Home() {
  return (
    <Layout>
      <Row>

        <Col xs={12}>
          <OrderBooks />
        </Col>

        <Col xs={12}>
          <Wallets />
        </Col>

      </Row>
    </Layout>
  );
}

export default Home;
